//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$red-color: red;


//Font-sizes
$ultra_small-size: 11px;
$small-size: 13px;
$small-middle-size: 14px;
$middle-size: 15px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.TextInput {
  width: 100%;
  height: 34px;
  font-size: 12px;
  background-color: #fafafa;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  color: #4a4a4a;
  &::placeholder {
    color: #9b9b9b;
  }
  &.borderless {
    border: 1px solid transparent;
    border-radius: 0;
  }
  &.transparent {
    background-color: transparent;
  }
  
  &.borderlessWhenDisabled:disabled {
    border: 1px solid transparent;
  }
}


.main_color {
  color: $primary-color;
}
