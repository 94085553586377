//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$red-color: red;


//Font-sizes
$ultra_small-size: 11px;
$small-size: 13px;
$small-middle-size: 14px;
$middle-size: 15px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


@font-face {
  font-family: San-Francisco;
  src: url('./assets/fonts/SF-Pro-Display-Regular.otf');
}

@font-face {
  font-family: San-Francisco-Text;
  src: url('./assets/fonts/SF-Pro-Text-Regular.otf');
}

* {
  outline: none;
}

input {
  padding: 0 10px;
}

a {
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}