//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$red-color: red;


//Font-sizes
$ultra_small-size: 11px;
$small-size: 13px;
$small-middle-size: 14px;
$middle-size: 15px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.DatePicker {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;

  .input {
    position: relative;
    display: flex;
    align-items: center;
    height: 34px;
    width: 100%;
    background-color: #fafafa;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
    &.disabled {
      cursor: not-allowed;
    }
    &.borderless {
      border: 1px solid transparent;
      border-radius: 0;
    }
    &.transparent {
      background-color: transparent;
    }
    &.borderlessWhenDisabled.disabled {
      border: 1px solid transparent;
    }

    .value {
      display: flex;
      align-items: center;
      flex: 1;
      padding: 0 12px;
      margin-right: -12px;
      height: 100%;
      font-size: 12px;
      color: #4a4a4a;
      overflow: hidden;
      white-space: nowrap;
      &.empty {
        color: #9b9b9b;
      }
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0;
      min-width: 30px;
      height: 100%;
    }
  }

  .popup {
    position: absolute;
    top: 38px;
    left: -50%;
    z-index: 1;
    width: 384px;
  }
}


@media (max-width: 615px) {
  .popup {
    left: 50%!important;
    transform: translateX(-50%)!important;
  }
}

@media (max-width: 385px) {
  .popup {
    left: -185px
  }
}
