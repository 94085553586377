//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$red-color: red;


//Font-sizes
$ultra_small-size: 11px;
$small-size: 13px;
$small-middle-size: 14px;
$middle-size: 15px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.InputError {
  width: 100%;
  position: relative;
  padding: 0;
  .wrapper {
    border: 1px solid red;
    overflow: hidden;
    & > * {
      margin: -1px;
    }
  }
  .error {
    position: absolute;
    color: red;
    font-size: 10px;
  }
}
